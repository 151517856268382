
import Highcharts from 'highcharts'
import { Chart } from 'highcharts-vue'
import stockInit from 'highcharts/modules/stock'
import { dateFormat } from '~/node_modules/highcharts/highcharts';

if (typeof Highcharts === 'object') {
  stockInit(Highcharts)
}

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    chartData: {
      default: null,
      type: Array,
    },
    chartCode: {
      type: String,
      default: 'UAE20',
    },
    chartType: {
      type: String,
      default: 'Spline',
    },

    chartTitle: {
      type: String,
      default: 'Chart',
    },
    axisTitle: {
      type: String,
      default: 'Value',
    },
    navigator: {
      type: Boolean,
      default: true,
    },
    chartHeight: {
      type: Number,
      default: 600,
    },
  },
  data() {
    return {
      yAxisTitle: this.$t('data-headers.value'),
      chartFeedData: [],
      groupingUnits: [
        [
          'week', // unit name
          [1], // allowed multiples
        ],
        ['month', [1, 2, 3, 4, 6]],
      ],
      chartOptionsSpline: {
        credits: {
          enabled: false,
        },
        chart: {
          height: this.chartHeight,
          spacingLeft: 0,
          spacingTop: 30,
          alignTicks: false,
          backgroundColor: '#f9f9f9',
          marginRight: 30,
          marginBottom: 50,
          marginLeft: 30,
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#eeeeee',
          plotBorderWidth: 1,
          style: {
            fontFamily: 'Proxima Nova, Helvetica , arial, _sans',
            fontSize: '14px',
          },
        },
        title: {
          text: this.chartTitle,
          margin: 30,
          style: {
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
        loading: {
          style: {
            backgroundColor: 'blue',
          },
          labelStyle: {
            color: 'white',
          },
        },
        rangeSelector: {
          allButtonsEnabled: true,
          selected: 4,

          labelStyle: {
            display: 'none',
          },

          buttons: [
            {
              type: 'day',
              count: 1,
              text: '1d',
              title: 'View 1 Day',
            },
            {
              type: 'week',
              count: 1,
              text: '1w',
              title: 'View 1 Week',
            },
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
            },
            {
              type: 'all',
              text: 'All',
              title: 'View all',
            },
          ],
          buttonSpacing: 2,
          buttonTheme: {
            // styles for the buttons
            fill: '#005797',
            stroke: '#fff',
            style: {
              color: '#fff',
              textTransform: 'uppercase',
            },
            states: {
              hover: {
                fill: '#666',
                stroke: '#fff',
              },
              select: {
                fill: '#666',
                stroke: '#fff',
                style: {
                  color: 'white',
                },
              },
            },
          },
          inputEnabled: true,
          inputStyle: {
            color: '#0369a1',
            backgroundColor: '#ffffff',
          },
          inputBoxBorderColor: 'transparent',
          inputBoxWidth: 120,
          inputBoxHeight: 18,
          inputEditDateFormat: '%d-%m-%Y',
          labelStyle: {
            color: '#666666',
            fontWeight: 'bold',
          },
        },
        navigator: {
          enabled: this.navigator,
          height: 60,
          maskFill: 'rgba(0, 149, 197, 0.2)',
          // maskInside: false,
          fillOpacity: 0.5,
          handles: {
            lineWidth: 1,
            width: 10,
            height: 17,
            backgroundColor: '#0095C5',
            borderColor: 'white',
          },
        },

        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
        },
        scrollbar: {
          enabled: false,
        },

        xAxis: [
          {
            startOfWeek: 2,
            lineColor: '#0095C5',
            labels: {
              style: {
                fontSize: '13',
              },
            },
          },
        ],
        yAxis: [
          {
            // alternateGridColor: '#f9f9f9',

            title: {
              text: this.axisTitle,
              style: {
                fontWeight: 'bold',
                fontSize: '14',
                textTransform: 'uppercase',
              },
            },
            lineWidth: 1,
            labels: {
              style: {
                fontSize: '13',
              },
            },
          },
        ],

        series: [
          {
            type: 'spline',
            name: this.chartCode,
            data: [],
          },
        ],
      },
      chartOptionsMinimal: {
        credits: {
          enabled: false,
        },
        chart: {
          height: this.chartHeight,
          spacingLeft: 0,
          spacingTop: 30,
          alignTicks: false,
          backgroundColor: '#ffffff',
          marginRight: 10,
          marginBottom: 30,
          marginLeft: 10,
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#eeeeee',
          plotBorderWidth: 1,
          style: {
            fontFamily: 'DM Sans, Helvetica , arial, _sans',
            fontSize: '14px',
          },
        },
        title: {
          text: this.chartTitle,
          margin: 30,
          style: {
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
        loading: {
          style: {
            backgroundColor: 'blue',
          },
          labelStyle: {
            color: 'white',
          },
        },
        rangeSelector: {
          selected: 3,
          enabled: false,
          allButtonsEnabled: true,
          labelStyle: {
            display: 'none',
          },

          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
            },
            {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
            },
          ],
          buttonSpacing: 2,
          buttonTheme: {
            // styles for the buttons
            fill: '#005797',
            stroke: '#fff',
            style: {
              color: '#fff',
              textTransform: 'uppercase',
            },
            states: {
              hover: {
                fill: '#666',
                stroke: '#fff',
              },
              select: {
                fill: '#666',
                stroke: '#fff',
                style: {
                  color: 'white',
                },
              },
            },
          },
          inputEnabled: true,
          inputStyle: {
            color: '#005797',
            backgroundColor: '#ffffff',
          },
          inputBoxBorderColor: 'transparent',
          inputBoxWidth: 120,
          inputBoxHeight: 18,
          inputEditDateFormat: '%d-%m-%Y',
          labelStyle: {
            color: '#666666',
            fontWeight: 'bold',
          },
        },
        navigator: {
          enabled: this.navigator,
          height: 60,
          maskFill: 'rgba(0, 149, 197, 0.2)',
          // maskInside: false,
          fillOpacity: 0.5,
          handles: {
            lineWidth: 1,
            width: 10,
            height: 17,
            backgroundColor: '#0095C5',
            borderColor: 'white',
          },
        },

        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
        },
        scrollbar: {
          enabled: false,
        },

        xAxis: [
          {
            lineColor: '#0095C5',
            labels: {
              style: {
                fontSize: '13',
              },
            },
          },
        ],
        yAxis: [
          {
            // alternateGridColor: '#f9f9f9',
            title: {
              text: this.axisTitle,
              style: {
                fontWeight: 'bold',
                fontSize: '14',
                textTransform: 'uppercase',
              },
            },
            lineWidth: 1,
            labels: {
              style: {
                fontSize: '13',
              },
            },
          },
        ],

        series: [
          {
            type: 'spline',
            name: this.chartCode,
            data: [],
          },
        ],
      },

      chartOptionsOhlc: {
        credits: {
          enabled: false,
        },
        chart: {
          height: 720,
          spacingLeft: 0,
          spacingTop: 30,
          alignTicks: false,
          backgroundColor: '#f9f9f9',
          marginRight: 30,
          marginBottom: 50,
          marginLeft: 30,
          className: 'highstock-style',
          plotBackgroundColor: '#fff',
          panning: false,
          plotBorderColor: '#eeeeee',
          plotBorderWidth: 1,
          style: {
            fontFamily: 'Proxima Nova, Helvetica , arial, _sans',
            fontSize: '14px',
          },
        },
        plotOptions: {
          series: {
            connectNulls: true,
          },
          candlestick: {
            color: '#009FC3',
            upColor: 'green',
            //candlestick is including color and upcolor, could you please set the ohlc the same, ohlc have only color
          },
          ohlc: {
            color: 'green',
            animation: {
              duration: 2000,
            }, //OHLC have not upcolor likes Candlestick
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.chartTitle,
          margin: 30,
          style: {
            fontSize: 14,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          },
        },
        loading: {
          style: {
            backgroundColor: 'blue',
          },
          labelStyle: {
            color: 'white',
          },
        },
        rangeSelector: {
          buttonSpacing: 2,
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
            },
            {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
            },
            {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
            },
          ],
          buttonTheme: {
            // styles for the buttons
            fill: '#005797',
            stroke: '#fff',
            style: {
              color: '#fff',
              textTransform: 'uppercase',
            },
            states: {
              hover: {
                fill: '#666',
                stroke: '#fff',
              },
              select: {
                fill: '#666',
                stroke: '#fff',
                style: {
                  color: 'white',
                },
              },
            },
          },
          inputEnabled: true,
          inputStyle: {
            color: '#0369a1',
            backgroundColor: '#ffffff',
          },
          inputBoxBorderColor: 'transparent',
          inputBoxWidth: 120,
          inputBoxHeight: 18,
          inputEditDateFormat: '%d-%m-%Y',
          labelStyle: {
            color: '#666666',
            fontWeight: 'bold',
          },

          selected: 2,
        },
        navigator: {
          enabled: this.navigator,
          height: 60,
          maskFill: 'rgba(0, 149, 197, 0.2)',
          // maskInside: false,
          fillOpacity: 0.5,
          handles: {
            lineWidth: 1,
            width: 10,
            height: 17,
            backgroundColor: '#0095C5',
            borderColor: 'white',
          },
        },

        tooltip: {
          yDecimals: 2,
          borderRadius: 8,
          borderWidth: 0,
          backgroundColor: '#333333',
          shadow: false,
          useHTML: true,
          padding: 10,
          style: {
            color: 'white',
            fontSize: '14',
            whiteSpace: 'nowrap',
          },
        },
        scrollbar: {
          enabled: false,
        },

        xAxis: [
          {
            lineColor: '#0095C5',
            labels: {
              style: {
                fontSize: '13',
              },
            },
          },
        ],
        yAxis: [
          {
            labels: {
              align: 'right',
              x: -3,
            },
            title: {
              text: 'OHLC',
            },
            height: '80%',
            lineWidth: 2,
            resize: {
              enabled: true,
            },
          },
          {
            labels: {
              align: 'right',
              x: -3,
            },
            title: {
              text: 'Volume',
            },
            top: '80%',
            height: '20%',
            offset: 0,
            lineWidth: 2,
          },
        ],

        series: [
          {
            type: 'candlestick',
            name: this.chartCode,
            data: [],
            dataGrouping: {
              units: this.groupingUnits,
            },
          },
          {
            type: 'column',
            name: 'Volume',
            data: [],
            dataGrouping: {
              units: this.groupingUnits,
            },
            yAxis: 1,
          },
        ],
      },
    }
  },
  computed: {
    endDate() {
      return this.$dayjs().format('YYYY-MM-DD')
    },
    startDate() {
      return this.$dayjs().subtract(12, 'month').format('YYYY-MM-DD')
      // return "01-01-2007";
    },
  },
  watch: {
    //update chart data when data changes
    chartData: {
      handler: function (newVal, oldVal) {
        this.processChartData()
      },
    },
  },

  mounted() {
    this.processChartData()
  },
  methods: {
    processChartData() {
      let data = this.chartData

      // if (this.chartData) {
      //     data = this.chartData
      // } else {
      //     data = this.chartFeedData
      // }
      if (this.chartType == 'Spline') {
        let tmp = []
        data.forEach((element) => {
          tmp.push([
            // Date.parse(this.$dayjs.utc(element.date, this.dateFormat).format()), // the date
            Date.parse(element.date), // the date
            parseFloat(element.currentIndex), // close
          ])
        })
        this.chartOptionsSpline.series[0].data = tmp
      } else if (this.chartType == 'Minimal') {
        let tmp = []
        data.forEach((element) => {
          tmp.push([
            element.d, // the date
            element.c, // close
          ])
        })
        this.chartOptionsMinimal.series[0].data = tmp
      } else if (this.chartType == 'OHLC') {
        let ohlc = []
        let volume = []
        data.forEach((element) => {
          ohlc.push([
            element.d, // the date
            element.o, // open
            element.h, // high
            element.l, // low
            element.c, // close// close
          ])
          volume.push([
            element.d, // the date
            element.vo, // the volume
          ])
        })
        this.chartOptionsOhlc.series[0].data = ohlc
        this.chartOptionsOhlc.series[1].data = volume
      }
    },
  },
}
